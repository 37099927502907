<template>
  <div>
    <el-button :disabled="disabled" @click="handleClick">{{
      buttonText
    }}</el-button>
    <!-- 滑块验证 -->
    <Captcha
      ref="captcha"
      :show="captcha.show"
      :imgs="captcha.imgList"
      @success="captchaSuccess"
      @close="captchaClose"
    />
  </div>
</template>

<script>
import Captcha from './index.vue';
export default {
  props: {
    value: String,
    type: String,
    validate: Function
  },
  components: { Captcha },
  data() {
    return {
      disabled: false,
      buttonText: '获取验证码',
      timer: null,
      countdown: 60,
      captcha: {
        show: false,
        imgList: [
        ]
      },
      request: {
        receiver: '',
        captcha: '',
        type: this.type
      }
    };
  },
  methods: {
    async handleClick() {
      // 校验
      const error = await this.validate();
      if (error) {
        return;
      }
      // 开始机器验证
      this.captcha.show = true;
    },
    // 机器验证通过
    async captchaSuccess() {
      // 关闭机器验证
      this.captcha.show = false;
      // 手机号需要加上+86
      let phone = this.value;
      if (this.$utils.reg('phone', phone)) {
        phone = '+86' + phone;
      }
      this.request.receiver = phone;
      // 禁用按钮
      this.disabled = true;
      // 启动倒计时
      this.startCountdown();
      // 发送验证码
      await this.getMachineCode();
      await this.sendVerificationCode();
    },
    // 机器验证关闭
    captchaClose() {
      this.captcha.show = false;
    },
    // 获取机器码
    async getMachineCode() {
      const res = await this.$api.getMachineCode({
        phone: this.request.receiver
      });
      if (res.code == 0) {
        this.request.captcha = res.data;
      }
    },
    // 发送验证码
    async sendVerificationCode() {
      const res = await this.$api.sendCode(this.request);
      if (res.code == 0) {
        notify('验证码已发送，请注意查收');
      } else {
        notify(res.message, 'warning');
      }
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
          this.buttonText = `${this.countdown}秒后重试`;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.buttonText = '获取验证码';
      this.countdown = 60;
      this.disabled = false;
    }
  },
  beforeDestroy() {
    this.stopCountdown();
  }
};
</script>
